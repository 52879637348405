import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from 'react-icons/fi'
import { MdRoofing } from 'react-icons/md'
import { FaHandshake } from 'react-icons/fa'


function Values() {
  const { rpdata } = useContext(GlobalDataContext);

  const whyChooseUs = 
  [
      {
          text:"Experience: With over 20 years of experience in the industry, we have the knowledge and expertise to provide high-quality services that meet our clients' needs."
      },
      {
          text:"Professionalism: Our team of experts is committed to delivering outstanding services with professionalism, integrity, and attention to detail."
      },
      {
          text:"Quality: We use the latest equipment and techniques to ensure that our services are of the highest quality and meet the industry standards."
      },
      {
          text:"Customer Satisfaction: Our goal is to exceed our clients' expectations by providing exceptional services and ensuring their complete satisfaction."
      }
  ]

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: <FiTarget fontSize={70} className='titleColorFt' />,
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: <MdRoofing fontSize={70} className='titleColorFt' />,
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: <FaHandshake fontSize={70} className='titleColorFt' />,
    },
  ]


  return (
    <>
      <div className="container mx-auto md:w-full py-10 flex flex-col justify-center items-center">
        {valuesData.slice(0,2).map((items, index) => {
          return (
            <div
              key={index}
              className="bg-white w-4/5 min-h-[380px] md:text-start  m-4 p-5 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out"
            >
              <div className="flex justify-center">{items.icon}</div>
              <div className="p-4 ">
                <h2 className="text-2xl text-center uppercase">
                  {items.title}
                </h2>
                <p className="text-center pb-3">{items.description}</p>
              </div>
            </div>
          );
        })}

{valuesData.slice(2).map((items, index) => {
          return (
            <div
              key={index}
              className="bg-white w-4/5 min-h-[380px] md:text-start  m-4 p-5 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out"
            >
              <div className="flex justify-center">{items.icon}</div>
              <div className="p-4 ">
                <h2 className="text-2xl text-center uppercase">
                  {items.title}
                </h2>
                {rpdata?.dbValues?.slice(2).map((items, index) => {
          return (
            <div key={index} className="pb-5">
              <h4 className="flex items-center">
                <span>{items.title} </span>
              </h4>
              {whyChooseUs.map((text, index) => {
                return (
                  <li key={index}>
                    <p className="pl-9">{text.text}</p>
                  </li>
                );
              })}
            </div>
          );
        })} 
              </div>
            </div>
          );
        })}

        {/* {rpdata?.dbValues?.slice(2).map((items, index) => {
          return (
            <div key={index} className="pb-5">
              <h4 className="flex items-center">
                <span>{items.title} </span>
              </h4>
              {whyChooseUs.map((text, index) => {
                return (
                  <li key={index}>
                    <p className="pl-9">{text.text}</p>
                  </li>
                );
              })}
            </div>
          );
        })} */}
      </div>
    </>
  );
}

export default Values;
